import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo";

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Distant December" />

		<h2>Distant December</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST17 distant december front.png"
			alt="Jesse Rivest - Distant December - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
			To be released on December 1, 2023.<br />
			(Actually released to streaming platforms on November 27 to allow time for uploading of lyrics.)
		</p>

		<h3>Words from Jesse</h3>
		<p>
			My first LP release since 2006!
		</p>
		<p>
			At first, this was meant to be an EP, but as I was putting it together, I saw room for a few extra songs and got excited about an LP.
			Also, this is the first release I've manufactured/printed since 2006;
			after <Link to="/music/01/"><span className="italic">Seventeen Oh-Two Oh-Six</span></Link>, I went strictly digital.
			I decided to print this one for "merch" reasons, really.
			I had thought quite a bit about merch and concluded that I wanted something small and light;
			something collectible rather than discardable; and something that could represent me and carry metadata.
			I can't afford vinyl records at this time, and I know that there are many folks out there that once collected CDs.
			Consider this CD a collectible item and a way to further support me on my journey.
		</p>
		<p>
			The first single off this release was <Link to="/music/16"><span className="italic">Look at What You've Got</span></Link>, which was released on November 3.
			The second single is <span className="italic">Softness in the Afterglow</span>, which I am simply stating here as it is being released at the same time as the LP is released.
		</p>
		<p>
			What is <span className="italic">Distant December</span>?
			I used to wonder if I'd ever release a Christmas/holiday album; a few folks even encouraged me to do so.
			Over the decades, I have grown to not fully feel or celebrate Christmas—not the way I did when I was a kid.
			I grew to dislike the pressure to buy stuff and to think of gifts, as well as the crazy traffic jam of everyone trying to do the same thing at the same time.
			However, having lived mostly in the southern hemisphere over 18 years, I had grown to miss some elements of the holidays.
			I missed the time-out we take—the pause in our lives to be with some friends or family and to share some meals, drinks, music, and laughs.
			I missed the cold weather and short days that wrapped those times.
			I realized I was missing the winter solstice and the social holiday break.
			I also realized that Christmas music was being used somewhat to intoxicate us and influence us to shop and consume.
			That confused me, as there are some Christmas tunes and Christmas albums I really love
			(<span className="italic">Rocky Mountain Christmas</span>, by John Denver, is one such album).
			Over time, I gravitated toward some songs that aren't necessarily Christmas tunes but easily are wintry or even December-y;
			on this LP there is only one song that was written clearly as a Christmas song,
			and that's <span className="italic">The Christmas Song</span> (which I can't help but include as I really do love this one).
			Other songs, like <span className="italic">Let it Snow</span>, <span className="italic">Winter Wonderland</span>, and <span className="italic">Aspenglow</span> do not mention or describe Christmas, but rather winterscapes and wintry warmth.
			I love these old songs.
			I've included a few of my own tunes that I dare not say belong with the ones written by old masters,
			but I do say feel wintry to me and suit my December-y vision.
		</p>
		<p>
			I hope <span className="italic">Distant December</span> leaves you feeling warm.
			Maybe you can share some warmth with a friend or family member.&nbsp;
			<Link to="/contact/">Drop me a line</Link> if you want to send some warmth back my way.
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>
				Softness in the Afterglow (4:45)<br />
				T3209952290 - © Jesse Rivest 2023 (SOCAN/BMI, CMRRA)<br />
				CA-6P8-23-00004 - © Jesse Rivest 2023 (PPL)
			</li>
			<li>
				Aspenglow (2:37)<br />
				T0708822405 - © BMG Ruby Songs 1970 (ASCAP)<br />
				CA-6P8-23-00005 - © Jesse Rivest 2023 (PPL)<br />
				written by John Denver
			</li>
			<li>
				Look at What You've Got (4:31)<br />
				T3209953215 - © Jesse Rivest 2023 (SOCAN/BMI, CMRRA)<br />
				CA-6P8-23-00003 - © Jesse Rivest 2023 (PPL)
			</li>
			<li>
				Let it Snow (3:28)<br />
				T0700953929 - © Cahn Music Company (ASCAP) / Quaytor Productions LLC (ASCAP) / WC Music Corp (ASCAP) 1945<br />
				CA-6P8-23-00006 - © Jesse Rivest 2023 (PPL)<br />
				written by Sammy Cahn and Jule Styne
			</li>
			<li>
				Winter Wonderland (2:14)<br />
				T0702004156 - © WC Music Corp (ASCAP) 1934<br />
				CA-6P8-23-00007 - © Jesse Rivest 2023 (PPL)<br />
				written by Felix Bernard and Richard B. Smith
			</li>
			<li>
				Reverie of Winter Warmth (4:01)<br />
				T3209953259 - © Jesse Rivest 2023 (SOCAN/BMI, CMRRA)<br />
				CA-6P8-23-00008 - © Jesse Rivest 2023 (PPL)<br />
			</li>
			<li>
				The Christmas Song (3:52)<br />
				T0700249611 - © Morris Edwin H & Co Inc (ASCAP) / Sony/ATV Tunes LLC (ASCAP) 1945<br />
				CA-6P8-23-00009 - © Jesse Rivest 2023 (PPL)<br />
				written by Melvin H Torme and Robert Wells
			</li>
			<li>
				Song for a Winter's Night (3:56)<br />
				T0715148836 - © Warner Bros Inc (ASCAP) / WC Music Corp (ASCAP) 1967<br />
				CA-6P8-23-00010 - © Jesse Rivest 2023 (PPL)<br />
				written by Gordon Lightfoot
			</li>
			<li>
				Bottle of Wine (5:46)<br />
				T9314934537 - © Jesse Rivest 2019 (SOCAN/BMI, CMRRA)<br />
				CA-6P8-23-00011 - © Jesse Rivest 2023 (PPL)<br />
			</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST17, © Jesse Rivest 2023, All Rights Reserved<br />
			UPC/EAN: 0198009372799<br />
			Released: December 1, 2023
		</p>

		<h3>Credits</h3>
		<p>
			Produced, recorded, mixed, and mastered by Jesse Rivest at his Little Bakery Studio in Brasilia, DF, Brazil.<br />
			Drums recorded by Marcos Pagani at Orbis Studio in Brasilia, DF, Brazil.<br />
			Songs written (except where otherwise indicated) and performed by Jesse Rivest.<br />
			Drums and percussion performed by Misael Barros.<br />
			Hammond B3 performed by Alexander Raichenok.<br />
			Organ bass performed by Jesse Rivest.<br />
			Acoustic and electric guitars performed by Jesse Rivest.<br />
			Piano performed by Jesse Rivest.<br />
			Backing vocals performed by Jesse Rivest.<br />
			Album cover artwork by Paula Calderón (www.paulacalderon.com.br, @paulacalderon.arte, 2023).<br />
			Album cover layout by Jesse Rivest.
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino for being supportive, always.<br />
			Thanks to Misael and Alexander for their performances!<br />
			Thanks to Tico de Moraes for his ears and feedback.
		</p>

		<h3>Extras</h3>

		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST17 distant december back.png"
			alt="Jesse Rivest - Distant December - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h4>Printings</h4>
		<p>
			First printing/pressing was October 2023: 100 units of duplicated, six-panel wallet CDs.
		</p>

		<h3 className="lyrics">Lyrics</h3>
		<div>
			<h4 className="lyrics__title">
				Softness in the Afterglow
			</h4>
			<h5 className="lyrics__copyright">
				© Jesse James Rivest, 2023
			</h5>
			<p>
				With this fall<br />
				I have held<br />
				My gaze through the window<br />
				Breathy fog<br />
				On the pane<br />
				Frosts into new crystals
			</p>
			<p>
				My mind drifts<br />
				Softly plays<br />
				Land a kiss on my cheek<br />
				Draft a poem<br />
				Air a tune<br />
				Reminisce of your grace
			</p>
			<p>
				Light is fair<br />
				Winter hair untamed<br />
				Very still<br />
				Such a thrilling scene
			</p>
			<p>
				Brightness in the window<br />
				Softness in the light that flows<br />
				Softness in the handle<br />
				Rightness in the hand that holds
			</p>
			<p>
				Oven warm<br />
				Greasy sheet<br />
				Cutters sinking through dough<br />
				Crackling fire<br />
				Seasoned wine<br />
				Stirring in the afternoon
			</p>
			<p>
				Light in wane<br />
				Winter mane thawing<br />
				Hang your coat<br />
				Bring your cheek to mine
			</p>
			<p>
				Mildness in the mantle<br />
				Caress in the one I hold<br />
				Softness in the handle<br />
				Rightness in the hand that holds
			</p>
			<p>
				Brightness in the laughter<br />
				Softness in the afterglow<br />
				Softness in the handle<br />
				Rightness in the hand that holds
			</p>

			<h4 className="lyrics__title">
				Aspenglow
			</h4>
			<h5 className="lyrics__copyright">
				© BMG Ruby Songs, 1970
			</h5>
			<p>
				See the sunlight through the pine<br />
				Taste the warm of winter wine<br />
				Dream of softly falling snow<br />
				Winterskol, Aspenglow
			</p>
			<p>
				As the winter days unfold<br />
				Hearts grow warmer with the cold<br />
				Peace of mind is all you know<br />
				Winterskol, Aspenglow
			</p>
			<p>
				Aspen is a life to live<br />
				See how much there is to give<br />
				See how strongly you believe<br />
				See how much you may receive
			</p>
			<p>
				Smiling faces all around<br />
				Laughter is the only sound<br />
				Memories that can’t grow old<br />
				Winterskol, Aspenglow
			</p>
			<p>
				Aspen is a life to live<br />
				See how much there is to give<br />
				See how strongly you believe<br />
				See how much you may receive
			</p>
			<p>
				See the sunlight through the pine<br />
				Taste the warm of winter wine<br />
				Dream of softly falling snow<br />
				Winterskol, Aspenglow<br />
				Winterskol, Aspenglow
			</p>

			<h4 className="lyrics__title">
				Look at What You've Got
			</h4>
			<h5 className="lyrics__copyright">
				© Jesse James Rivest, 2023
			</h5>
			<p>
				Oh, here come old memories<br />
				Oh, how they shimmer bright as stars<br />
				How I love all them for what they were<br />
				Even wish I could stop and return
			</p>
			<p>
				Oh, they take me back, those old songs<br />
				But I know that's not where I belong
			</p>
			<p>
				Oh, here come the holidays<br />
				Still don't have any plans, just time free<br />
				How I long just to go far away<br />
				Fly to some warmer place and free fall<br />
			</p>
			<p>
				Oh, they take me back, those strung lights<br />
				And the songs we'd sing those cold nights
			</p>
			<p>
				Take in who you've got around you now<br />
				Look at where you're at and don't look down<br />
				Find someone who needs a friendly smile<br />
				And share with them all that you've got
			</p>
			<p>
				Snow is falling, a soft blanket accrues<br />
				Through the windows kids are staring at you<br />
				How you're dancing and entrancing<br />
				You're the last thing they'll see<br />
				As they fall asleep
			</p>
			<p>
				Look at what you've got around you now<br />
				Enjoy where you're at and don't look down<br />
				Find someone who needs a friendly smile<br />
				And share with them all that you've got
			</p>

			<h4 className="lyrics__title">
				Let it Snow
			</h4>
			<h5 className="lyrics__copyright">
				© Cahn Music Company / Quaytor Productions LLC / WC Music Corp, 1945
			</h5>
			<p>
				Oh, the weather outside is frightful<br />
				But the fire is so delightful<br />
				And since we've no place to go<br />
				Let it snow, let it snow, let it snow
			</p>
			<p>
				It doesn't show signs of stopping<br />
				And I've brought some corn for popping<br />
				The lights are turned way down low<br />
				Let it snow, let it snow, let it snow
			</p>
			<p>
				When we finally kiss goodnight<br />
				How I'll hate going out in the storm<br />
				But if you really hold me tight<br />
				All the way home I'll be warm
			</p>
			<p>
				Oh, the fire is slowly dying<br />
				And, my dear, we're still goodbye-ing<br />
				As long as you love me so<br />
				Let it snow, let it snow, let it snow
			</p>
			<p>
				When we finally kiss goodnight<br />
				How I'll hate going out in the storm<br />
				But if you really hold me tight<br />
				All the way home I'll be warm
			</p>
			<p>
				Oh, the fire is slowly dying<br />
				And, my dear, we're still goodbye-ing<br />
				As long as you love me so<br />
				Let it snow, let it snow, let it snow<br />
				Let it snow, let it snow, let it snow<br />
				Let it snow, let it snow, let it snow
			</p>

			<h4 className="lyrics__title">
				Winter Wonderland
			</h4>
			<h5 className="lyrics__copyright">
				© WC Music Corp, 1934
			</h5>
			<p>
				Sleigh bells ring, are you listening?<br />
				In the lane snow is glistening<br />
				A beautiful sight, we’re happy tonight<br />
				Walking in a winter wonderland
			</p>
			<p>
				Gone away is the bluebird<br />
				Here to stay is a new bird<br />
				He sings a love song as we go along<br />
				Walking in a winter wonderland
			</p>
			<p>
				In the meadow we can build a snowman<br />
				Then pretend that he is Parson Brown<br />
				He’ll say “Are you married?” We’ll say “No man!<br />
				But you can do the job when you’re in town”
			</p>
			<p>
				Later on, we’ll conspire<br />
				As we dream by the fire<br />
				To face unafraid the plans that we’ve made<br />
				Walking in a winter wonderland
			</p>
			<p>
				In the meadow we can build a snowman<br />
				Then pretend that he is Parson Brown<br />
				He’ll say “Are you married?” We’ll say “No man!<br />
				But you can do the job when you’re in town”
			</p>
			<p>
				Later on, we’ll conspire<br />
				As we dream by the fire<br />
				To face unafraid the plans that we’ve made<br />
				Walking in a winter wonderland<br />
				Walking in a winter wonderland<br />
				Walking in a winter wonderland
			</p>

			<h4 className="lyrics__title">
				Reverie of Winter Warmth
			</h4>
			<h5 className="lyrics__copyright">
				© Jesse James Rivest, 2023
			</h5>
			<p>
				(instrumental)
			</p>

			<h4 className="lyrics__title">
				The Christmas Song
			</h4>
			<h5 className="lyrics__copyright">
				© Morris Edwin H & Co Inc / Sony/ATV Tunes LLC, 1945
			</h5>
			<p>
				Chestnuts roasting on an open fire<br />
				Jack Frost nipping at your nose<br />
				Yuletide carols being sung by a choir<br />
				And folks dressed up like Eskimos
			</p>
			<p>
				Everybody knows a turkey and some mistletoe<br />
				Help to make the season bright<br />
				Tiny tots with their eyes all aglow<br />
				Will find it hard to sleep tonight
			</p>
			<p>
				They know that Santa's on his way<br />
				He's loaded lots of toys and goodies on his sleigh<br />
				And every mother's child is gonna spy<br />
				To see if reindeer really know how to fly
			</p>
			<p>
				And so I'm offering this simple phrase<br />
				To kids from one to ninety-two<br />
				Although it's been said many times, many ways<br />
				Merry Christmas to you
			</p>
			<p>
				And so I'm offering this simple phrase<br />
				To kids from one to ninety-two<br />
				Although it's been said many times, many ways<br />
				Merry Christmas to you
			</p>

			<h4 className="lyrics__title">
				Song for a Winter's Night
			</h4>
			<h5 className="lyrics__copyright">
				© Warner Bros Inc / WC Music Corp, 1967
			</h5>
			<p>
				The lamp is burning low upon my table top<br />
				The snow is softly falling<br />
				The air is still in the silence of my room<br />
				I hear your voice softly calling
			</p>
			<p>
				If I could only have you near<br />
				To breathe a sigh or two<br />
				I would be happy just to hold the hands I love<br />
				On this winter night with you
			</p>
			<p>
				The smoke is rising in the shadows overhead<br />
				My glass is almost empty<br />
				I read again between the lines upon the page<br />
				The words of love you sent me
			</p>
			<p>
				If I could know within my heart<br />
				That you were lonely too<br />
				I would be happy just to hold the hands I love<br />
				On this winter night with you
			</p>
			<p>
				The fire is dying now, my lamp is growing dim<br />
				The shades of night are lifting<br />
				The morning light steals across my windowpane<br />
				Where webs of snow are drifting
			</p>
			<p>
				If I could only have you near<br />
				To breathe a sigh or two<br />
				I would be happy just to hold the hands I love<br />
				And to be once again with you<br />
				To be once again with you
			</p>

			<h4 className="lyrics__title">
				Bottle of Wine
			</h4>
			<h5 className="lyrics__copyright">
				© Jesse James Rivest, 2019
			</h5>
			<p>
				Grapes on the vine, lustrous in the late sun<br />
				On the row, it's a process so slow<br />
				Passion and care, love in the inconstant<br />
				How it starts, every detail takes part
			</p>
			<p>
				As she walked in she set her scene<br />
				That slender sense that some things just can't be<br />
				Oooo, we passed the night amicably<br />
				And you still hum your song unsung<br />
				Oh, bottle of wine
			</p>
			<p>
				Bottle of wine, dormant yet expectant<br />
				On reserve, you're on special reserve<br />
				Tinted behold, deferred celebration<br />
				Inner glow, ripe and ready to flow
			</p>
			<p>
				When I meet her you will, too<br />
				She will shed her tannin colours off for you<br />
				Oooo, wipe off your dust, sparkle and shine!<br />
				For until then you will remain<br />
				Oh, bottle of wine
			</p>
			<p>
				When I meet her you will, too<br />
				She will shed her tannin colours off for you, yes for me, too<br />
				Oooo, wipe off your dust, sparkle and shine!<br />
				For until then you will remain<br />
				An unsung refrain<br />
				Oh, bottle of wine
			</p>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
